import React, { useState } from 'react';

import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import AdminTable from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import { Checkbox, DateField, TextField } from 'src/components/formik-fields/formik-fields';
import Panel from 'src/components/panel/panel';
import { resolveViolationDate } from 'src/utils/helper/suspension-utils';
import { NavigationState } from 'src/utils/typedefs/navigation';
import * as Yup from 'yup';

import CustomDialog from '../custom-dialog/custom-dialog';
import FormErrorMessage from '../form-error-message/form-error-message';
import Icon from '../icon/icon';
import InputLabel from '../input-label/input-label';
import { DELETE_SUSPENSION, LIST_PLAYER_VIOLATIONS } from '../players/players-queries';
import Spinner from '../spinner/spinner';
import * as styles from './suspension-form.module.less';

const DeleteSuspensionDialog = ({ id, suspensionId }) => {
  const { t } = useTranslation();
  const [dialog, openDialog] = useState(false);

  const [deleteSuspension, { loading }] = useMutation(DELETE_SUSPENSION, {
    client: meshGatewayClient,
  });

  const onSubmit = async () => {
    try {
      await deleteSuspension({
        variables: {
          id: suspensionId,
        },
      });
      const state: NavigationState = {
        popup: {
          message: t('suspension deleted'),
        },
      };
      navigate(`/players/${id}?tab=suspensions`, { state });
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <Button
        level="warningLink"
        type="button"
        onClick={() => openDialog(true)}
        spacing={{ margins: { xs: 'horizontal' } }}
      >
        <Icon name="md-delete" className={styles.icon} />
        {t('delete suspension')}
      </Button>
      <CustomDialog
        title={t('delete suspension')}
        open={dialog}
        content={<p>{t('delete suspension confirm')}</p>}
        onClose={() => openDialog(false)}
        actions={
          <>
            <Button level="tertiary" onClick={() => openDialog(false)}>
              {t('no, cancel')}
            </Button>
            <Button level="warning" onClick={onSubmit} loading={loading}>
              {t('yes, delete')}
            </Button>
          </>
        }
      />
    </>
  );
};

interface Props {
  id: string;
  formVals: any;
  handleSubmit: (values) => void;
  suspensionId?: string;
  loading: boolean;
  submitError: ApolloError | undefined;
  editSelectedPoints: (id: string, checked: boolean) => void;
}
const SuspensionForm: React.FC<Props> = ({
  id,
  formVals,
  handleSubmit,
  suspensionId,
  loading,
  submitError,
  editSelectedPoints,
}) => {
  const { t } = useTranslation();

  const { loading: loadingViolations, data: violations } = useQuery(LIST_PLAYER_VIOLATIONS, {
    client: meshGatewayClient,
    variables: { uaid: id, expired: false },
  });

  const cols = [
    {
      key: 'select',
      title: ' ',
      getValue: (d) => <Checkbox label="" onChecked={(checked) => editSelectedPoints(d?.id, checked)} />,
    },
    { key: 'code', title: t('code'), getValue: (m) => m?.suspensionCode?.code },
    {
      key: 'points',
      title: t('points'),
      getValue: (m) => m?.suspensionCode?.points,
    },
    {
      key: 'dateReported',
      title: t('date reported'),
      getValue: (m) => resolveViolationDate(m, 'violationDate'),
    },
    {
      key: 'expDate',
      title: t('exp date'),
      getValue: (m) => resolveViolationDate(m, 'expiryDate'),
    },
    {
      key: 'reporter',
      title: t('reporter'),
      getValue: (m) => m?.reporter || t('n/a'),
    },
    {
      key: 'tournament',
      title: t('tournament'),
      getValue: (m) => m?.tournament || t('n/a'),
    },
  ];

  const validationSchema = Yup.object().shape({
    startDate: Yup.date(),
    endDate: Yup.date().min(Yup.ref('startDate'), t('end date min')),
    comment: Yup.string().max(500, t('500 characters max')),
  });

  return (
    <Formik initialValues={formVals} onSubmit={(values) => handleSubmit(values)} validationSchema={validationSchema}>
      {({ errors, touched }) => {
        return (
          <Form data-testid="suspension-form">
            <Panel>
              <div className={styles.width}>
                <InputLabel className={styles.bold} spacing={{ margins: { xs: 'vertical' } }}>
                  {t('suspension start date')}
                </InputLabel>
                <DateField
                  name={'startDate'}
                  datePickerProps={{
                    spacing: {
                      margins: { xxs: 'top', lg: 'bottom', md: 'right' },
                    },
                  }}
                />
                <InputLabel className={styles.bold} spacing={{ margins: { xs: 'vertical' } }}>
                  {t('suspension end date')}
                </InputLabel>
                <DateField
                  name={'endDate'}
                  datePickerProps={{
                    spacing: { margins: { xxs: 'top', md: 'right' } },
                  }}
                />
                {errors?.endDate && touched?.endDate && <FormErrorMessage message={errors.endDate} />}
              </div>

              <InputLabel className={styles.bold} spacing={{ margins: { xs: 'vertical' } }}>
                {t('select violations')}
              </InputLabel>
              <div className={styles.border}>
                {loadingViolations ? (
                  <Spinner />
                ) : (
                  <AdminTable
                    data={violations?.listViolationsForPlayer}
                    columns={cols}
                    spacing={{ margins: { sm: 'vertical' } }}
                  />
                )}
              </div>

              <InputLabel spacing={{ margins: { lg: 'top' } }}>{t('comments')}</InputLabel>
              <div className={styles.fieldWidth}>
                <TextField name={'comment'} fullWidth multiline rows={8} spacing={{ margins: { xxs: 'top' } }} />
              </div>
            </Panel>

            <Grid container>
              <Button
                level="tertiary"
                spacing={{ margins: { sm: 'right' } }}
                onClick={() => navigate(`/players/${id}?tab=suspensions`)}
                type="button"
              >
                {t('cancel')}
              </Button>
              <Button type="submit" loading={loading}>
                {t('save')}
              </Button>
              {suspensionId && <DeleteSuspensionDialog id={id} suspensionId={suspensionId} />}
            </Grid>
            {submitError && <FormErrorMessage message={submitError?.message} />}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SuspensionForm;
